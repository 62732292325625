
























import Vue from 'vue';
import BasketResourceViewModel from '@/src/services/viewModel/resource/BasketResourceViewModel';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  props: {
    value: { type: Object, default: undefined },
    saveFunction: { type: Function, default: () => {} },
    proceedFunction: { type: Function, default: () => {} },
  },
  computed: {
    paymentAmount(): number {
      return this.innerValue.orderBasket.grossTotal;
    },
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onPayed(data: BasketResourceViewModel) {
      if (!this.innerValue.orderBasket.onlinePayment.transactionId) {
        this.innerValue.orderBasket.paymentMethod = data.paymentMethod;
        this.innerValue.orderBasket.onlinePayment.fromModel(data.onlinePayment);
        this.saveFunction!();
      }
      this.proceedFunction!();
    },
  },
});
